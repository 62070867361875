@import url('https://fonts.googleapis.com/css2?family=MedievalSharp&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital@1&display=swap');

.nft-container {
  /* background-image: url('../img/background.png'); */
  /* background-position: center; */
  /* background-repeat: no-repeat; */
  /* background-size: cover; */
  background-color: #051626;
  color: #fff;
  /* padding: 8rem 0; */
  overflow: hidden;
}

.nft-container *,
.nft-header * {
  font-family: 'MedievalSharp', cursive !important;
}

.container-75-w {
  width: 100%;
}

.nft-header {
  background-color: #051626;
}

.nft-container > h1,
h2,
h3,
h4 p {
  color: #fff;
}

.nft-button-1 {
  border: 1px solid #fff;
  background: #113764;
  width: 100%;
  padding: 7px 0;
  height: 34px;
  text-align: center;
}

.nft-button-2 {
  border: none;
  background: #113764;
  width: 100%;
  padding: 7px 0;
  height: 34px;
  text-align: center;
}

.nft-button-cut-corner {
  position: relative;
  isolation: isolate;
  height: 34px;
  padding: 7px 0;
  background: transparent;
  border: none;
  text-align: center;
  width: 100%;
}

.nft-button-cut-corner::before {
  content: '';
  position: absolute;
  inset: 0;
  background: #0f3344;
  clip-path: polygon(
    0 0,
    calc(100% - 14px) 0,
    100% 14px,
    100% 100%,
    14px 100%,
    0 calc(100% - 14px)
  );
  z-index: -1;
}

.nft-button-cut-corner::after {
  content: '';
  position: absolute;
  inset: 0;
  background: #fff;
  clip-path: polygon(
    0 0,
    calc(100% - 14px) 0,
    100% 14px,
    100% 100%,
    14px 100%,
    0 calc(100% - 14px),
    0 0,
    1px 1px,
    1px calc(100% - 14px - 0.41px),
    calc(14px + 0.41px) calc(100% - 1px),
    calc(100% - 1px) calc(100% - 1px),
    calc(100% - 1px) calc(14px + 0.41px),
    calc(100% - 14px - 0.41px) 1px,
    1px 1px
  );
  
}

.nft-border-diamond-container,
.nft-border-lightdiamond-container {
  position: relative;
}

.nft-border-diamond-container::after {
  content: '';
  position: absolute;
  right: -22px;
  top: -15px;
  background: transparent url('../img/rightdesign.png') no-repeat;
  width: 40px;
  height: calc(100% + 20px);
  background-size: contain;
  z-index: 2;
}

.nft-border-diamond-container::before {
  content: '';
  position: absolute;
  left: -22px;
  top: -15px;
  background: transparent url('../img/leftdesign.png') no-repeat;
  width: 40px;
  height: calc(100% + 20px);
  background-size: contain;
  z-index: 2;
}

.nft-border-diamond {
  position: relative;
  width: 100%;
  height: 100%;
  border-top: 4px solid #6e8de1;
  border-bottom: 4px solid #6e8de1;
}

.nft-border-diamond::before {
  content: '';
  position: absolute;
  top: -7px;
  width: 100%;
  height: 6px;
  background: transparent url('../img/borderdesigntopblue.svg') no-repeat;
  background-size: contain;
  background-position: center;
  display: flex;
  justify-content: center;
  z-index: 1;
}

.nft-border-diamond::after {
  content: '';
  position: absolute;
  bottom: -7px;
  width: 100%;
  height: 6px;
  background: transparent url('../img/borderdesignbottomblue.svg') no-repeat;
  background-size: contain;
  background-position: center;
  display: flex;
  justify-content: center;
  z-index: 1;
}

.nft-transaction-board {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.nft-font-barlow * {
  font-family: 'Barlow', sans-serif !important;
}

.nft-border-card-diamond-price {
  font-size: 10px;
}

.nft-border-card-diamond-container {
  position: relative;
  top: -35px;
  width: 90%;
  margin: 0 auto;
}

.nft-border-card-diamond-container::after {
  content: '';
  position: absolute;
  right: 0;
  top: -15px;
  background: transparent url('../img/rightdesign.png') no-repeat;
  width: 22%;
  height: calc(100% + 20px);
  background-size: contain;
  z-index: 2;
}

.nft-border-card-diamond-container::before {
  content: '';
  position: absolute;
  left: 0;
  top: -15px;
  background: transparent url('../img/leftdesign.png') no-repeat;
  width: 22%;
  height: calc(100% + 20px);
  background-size: contain;
  z-index: 2;
}

.nft-border-card-diamond {
  position: relative;
  width: 80%;
  height: 100%;
  margin: 0 auto;
  border-top: 2px solid #6e8de1;
  border-bottom: 2px solid #6e8de1;
  background: #051626;
  display: block;
  justify-content: center;
  align-items: center;
  padding: 3px 0;
}


.nft-border-card-diamond::before {
  content: '';
  position: absolute;
  top: -4px;
  width: 100%;
  height: 4px;
  background: transparent url('../img/borderdesigntopblue.svg') no-repeat;
  background-size: contain;
  background-position: center;
  display: flex;
  justify-content: center;
  z-index: 1;
}

.nft-border-card-diamond::after {
  content: '';
  position: absolute;
  bottom: -4px;
  width: 100%;
  height: 4px;
  background: transparent url('../img/borderdesignbottomblue.svg') no-repeat;
  background-size: contain;
  background-position: center;
  display: flex;
  justify-content: center;
  z-index: 1;
}

.card-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.option-box-container {
  position: relative;
  padding: 15px 0;
  border-top: 2px solid #fff;
  border-bottom: 2px solid #fff;
}

.option-box-container::before {
  content: '';
  position: absolute;
  top: -5px;
  width: 100%;
  height: 4px;
  background: transparent url('../img/borderdesigntopwhite.svg') no-repeat;
  background-size: contain;
  background-position: center;
  display: flex;
  justify-content: center;
  z-index: 1;
}

.option-box-container::after {
  content: '';
  position: absolute;
  bottom: -5px;
  width: 100%;
  height: 4px;
  background: transparent url('../img/borderdesignbottomwhite.svg') no-repeat;
  background-size: contain;
  background-position: center;
  display: flex;
  justify-content: center;
  z-index: 1;
}

.option-box {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15px;
  position: relative;
  padding: 20px;
  z-index: 1;
}

.option-box::before {
  content: '';
  position: absolute;
  border: 1px solid #fff;
  inset: 0;
  z-index: -1;
}

.option-box::after {
  content: '';
  position: absolute;
  border: 1px solid #fff;
  height: calc(100% + 10px);
  width: 96.5%;
  inset: -2% 2%;
  z-index: -1;
}

.leftsidebar {
  padding-top: 40px;
}

.traits-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  width: 100%;
  text-align: center;
}

.traits-box {
  position: relative;
}

.traits-number {
  position: absolute;
  inset: 40% 0%;
  font-size: 24px;
}

.button-back {
  border: none;
  background: transparent;
  display: grid;
  grid-template-columns: 2fr 1fr;
  width: 75px;
  align-items: center;
}

.button-back > img {
  width: 100%;
}

.nftFontFamily * {
  font-family: 'MedievalSharp', cursive !important;
  color: #fff;
}

.horizontal-line-bluewhale {
  height: 1px;
  width: 70%;
  margin: 0 auto;
  background: #0d2c49;
}

.nft-modal-border {
  position: relative;
  padding: 20px 0;
}

.nft-modal-border::before {
  content: '';
  position: absolute;
  top: -11px;
  width: 100%;
  height: 22px;
  background: transparent url('../img/borderdesigntoplightblue.svg') no-repeat;
  background-size: contain;
  background-position: center;
  display: flex;
  justify-content: center;
}

.nft-modal-border::after {
  content: '';
  position: absolute;
  bottom: -24px;
  width: 100%;
  height: 22px;
  background: transparent url('../img/borderdesignbottomlightblue.svg')
    no-repeat;
  background-size: contain;
  background-position: center;
  display: flex;
  justify-content: center;
}

.nft-border-lightdiamond-container {
  border: 4px solid #b4c6f4;
}

.nft-border-lightdiamond-container::after {
  content: '';
  position: absolute;
  right: -13px;
  top: -11px;
  background: transparent url('../img/rightdesign.png') no-repeat;
  width: 40px;
  height: calc(100% + 20px);
  background-size: contain;
  z-index: 2;
}

.nft-border-lightdiamond-container::before {
  content: '';
  position: absolute;
  left: -13px;
  top: -11px;
  background: transparent url('../img/leftdesign.png') no-repeat;
  width: 40px;
  height: calc(100% + 20px);
  background-size: contain;
  z-index: 2;
}

.nft-border-lightdiamond {
  position: relative;
  width: 100%;
  height: 100%;
}

.nft-border-lightdiamond::before {
  content: '';
  position: absolute;
  top: -12px;
  width: 100%;
  height: 22px;
  background: transparent url('../img/borderdesigntoplightblue.svg') no-repeat;
  background-size: contain;
  background-position: center;
  display: flex;
  justify-content: center;
  z-index: 1;
}

.nft-border-lightdiamond::after {
  content: '';
  position: absolute;
  bottom: -23px;
  width: 100%;
  height: 22px;
  background: transparent url('../img/borderdesignbottomlightblue.svg')
    no-repeat;
  background-size: contain;
  background-position: center;
  display: flex;
  justify-content: center;
  z-index: 1;
}

.sort-button-wrap {
  filter: drop-shadow(1px -5px 5px rgba(0, 0, 0, 0.5))
    drop-shadow(1px 5px 5px rgba(0, 0, 0, 0.5));
}

.sort-button {
  display: flex;
  justify-content: center;
  position: relative;
  clip-path: polygon(
    10% 0%,
    70% 0%,
    100% 0%,
    100% 70%,
    100% 100%,
    0% 100%,
    0% 70%,
    0% 30%
  );
  background: #051626;
}

.sort-button > select {
  background-color: #051626;
  border: none;
  outline: none;
  cursor: pointer;
}

.sort-button-divider {
  background: transparent url('../img/dash.svg') no-repeat;
  background-size: cover;
  background-position: center;
  width: 35px;
}

.traits-label-filter {
  width: 60%;
  font-size: 14px;
  height: 24px;
  padding: 0;
}

.traits-label-filter::before {
  background: transparent !important;
}

@media (min-width: 992px) {
  .card-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }

  .leftsidebar {
    padding-right: 40%;
  }

  .container-75-w {
    width: 75%;
  }

  .traits-container {
    grid-template-columns: repeat(4, 1fr);
    height: 100%;
  }

  .traits-number {
    font-size: 30px;
  }
}

.nft-button-cut-corner-transparent {
  position: relative;
  isolation: isolate;
  height: 34px;
  padding: 7px 0;
  background: transparent;
  border: none;
  text-align: center;
  width: 100%;
}
.nft-button-cut-corner-transparent::before {
  content: '';
  position: absolute;
  inset: 0;
  background: transparent;
  clip-path: polygon(
    0 0,
    calc(100% - 14px) 0,
    100% 14px,
    100% 100%,
    14px 100%,
    0 calc(100% - 14px)
  );
  z-index: 1;
}

.nft-button-cut-corner-transparent::after {
  content: '';
  position: absolute;
  inset: 0;
  background: #fff;
  clip-path: polygon(
    0 0,
    calc(100% - 14px) 0,
    100% 14px,
    100% 100%,
    14px 100%,
    0 calc(100% - 14px),
    0 0,
    1px 1px,
    1px calc(100% - 14px - 0.41px),
    calc(14px + 0.41px) calc(100% - 1px),
    calc(100% - 1px) calc(100% - 1px),
    calc(100% - 1px) calc(14px + 0.41px),
    calc(100% - 14px - 0.41px) 1px,
    1px 1px
  );
}

.nft-tooltip-cut-corner {
  position: relative;
  isolation: isolate;
  height: 65px;
  padding: 7px 0;
  background: transparent;
  border: none;
  text-align: center;
  width: 100%;
  font-size: 15px;
}

.nft-info-icon {
  height: 20px;
  margin-left: 15px;
  margin-bottom: 11px;
  cursor: pointer;
}

.nft-info:hover > .nft-tooltip-cut-corner  {
  visibility: visible;
}

.nft-hide {
  visibility: hidden;
}

.nft-tooltip-cut-corner::before {
  content: '';
  position: absolute;
  inset: 0;
  background: #0f3344;
  clip-path: polygon(
    0 0,
    calc(100% - 14px) 0,
    100% 14px,
    100% 100%,
    14px 100%,
    0 calc(100% - 14px)
  );
  z-index: -1;
}

.nft-tooltip-cut-corner::after {
  content: '';
  position: absolute;
  inset: 0;
  background: #fff;
  clip-path: polygon(
    0 0,
    calc(100% - 14px) 0,
    100% 14px,
    100% 100%,
    14px 100%,
    0 calc(100% - 14px),
    0 0,
    1px 1px,
    1px calc(100% - 14px - 0.41px),
    calc(14px + 0.41px) calc(100% - 1px),
    calc(100% - 1px) calc(100% - 1px),
    calc(100% - 1px) calc(14px + 0.41px),
    calc(100% - 14px - 0.41px) 1px,
    1px 1px
  );
  
}