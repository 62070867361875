.homepage-container {
  min-height: 100vh;
}
.homepage-homepage27148 {
  /* width: 1280px;*/
  height: 1923px;
  display: flex;
  overflow: hidden;
  position: relative;
  /* max-width: 1280px; */
  box-sizing: border-box;
  align-items: flex-start;
  border-color: transparent;
  background-color: rgba(255, 255, 255, 1);
}
.homepage-frame223827150 {
  top: 0px;
  left: 0px;
  width: 25%;
  height: 2100px;
  display: flex;
  justify-content: end;
  overflow: hidden;
  position: fixed;
  box-sizing: border-box;
  align-items: flex-start;
  border-color: transparent;
}
.homepage-group62227151 {
  top: 58px;
  left: 125px;
  height: 582px;
  display: block;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  padding-top: 58px;
  padding-right: 58px;
}
.homepage-text {
  top: 566px;
  right: 66px;
  color: var(--dl-color-ubx_text__dark-100d1bbf9);
  height: auto;
  align-self: auto;
  text-align: right;
  line-height: 16px;
  text-decoration: none;
}
.homepage-group427153 {
  top: 526px;
  left: 71px;
  width: 96px;
  height: 16px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
}
.homepage-frame61827154 {
  top: 510px;
  right: 51px;
  width: 96px;
  display: flex;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
}
.homepage-image {
  width: 16px;
  height: 16px;
  margin: 0 24px 0 0;
  position: relative;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.homepage-image01 {
  width: 16px;
  height: 16px;
  margin: 0 24px 0 0;
  position: relative;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.homepage-image02 {
  width: 16px;
  height: 16px;
  position: relative;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.homepage-group327158 {
  right: 68px;
  top: 170px;
  display: block;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
}
.homepage-text01 {
  top: 40px;
  left: 0px;
  color: var(--dl-color-ubx_background-gray800e8080);
  width: 86px;
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: right;
  line-height: 16px;
  text-decoration: none;
}
.homepage-text02 {
  top: 0px;
  left: 3px;
  color: var(--dl-color-ubx_background-gray800e8080);
  width: 83px;
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: right;
  line-height: 16px;
  text-decoration: none;
}
.homepage-text03 {
  top: 80px;
  left: 1px;
  color: var(--dl-color-ubx_background-gray800e8080);
  width: 86px;
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: right;
  line-height: 16px;
  text-decoration: none;
}
.homepage-text04 {
  top: 120px;
  left: 0px;
  color: var(--dl-color-ubx_background-gray800e8080);
  width: 86px;
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: right;
  line-height: 16px;
  text-decoration: none;
}
.homepage-text05 {
  top: 0px;
  left: 96px;
  color: var(--dl-color-ubx_background-gray800e8080);
  height: auto;
  align-self: auto;
  text-align: right;
  line-height: 28px;
  text-decoration: none;
}
.homepage-frame61727164 {
  top: 52px;
  left: 95px;
  width: 72px;
  display: flex;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
}
.homepage-image03 {
  width: 24px;
  height: 24px;
  margin: 0 24px 0 0;
  position: relative;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.homepage-image04 {
  width: 24px;
  height: 24px;
  position: relative;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.homepage-frame224027172 {
  top: 0px;
  left: 336px;
  width: 940px;
  height: 2100px;
  display: flex;
  overflow: hidden;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  border-color: transparent;
}
.homepage-frame224127173 {
  top: 0px;
  left: 25%;
  width: 80%;
  height: 2100px;
  display: flex;
  overflow: hidden;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  border-color: transparent;
}
.homepage-image05 {
  top: 1391px;
  left: 45%;
  width: 55%;
  height: 532px;
  position: absolute;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
  background-color: var(--dl-color-ubx_background-gray1066c1e);
}
.homepage-group427175 {
  top: 1741.9874267578125px;
  left: 49%;
  width: 81px;
  height: 15.114858627319336px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
}
.homepage-frame61827176 {
  top: 0px;
  left: 0px;
  width: 81px;
  height: 15.114858627319336px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  border-color: transparent;
}
.homepage-image06 {
  width: 16px;
  height: 16px;
  margin: 0 24px 0 0;
  position: relative;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.homepage-image07 {
  width: 16px;
  height: 16px;
  margin: 0 24px 0 0;
  position: relative;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.homepage-image08 {
  width: 16px;
  height: 16px;
  position: relative;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.homepage-image09 {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 635px;
  position: absolute;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.homepage-text06 {
  top: 660px;
  left: 0px;
  color: var(--dl-color-ubx_text__dark-100d1bbf9);
  width: 318px;
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: left;
  line-height: 32px;
  text-decoration: none;
}
.homepage-group61927182 {
  top: 1270px;
  left: 4.547473508864641e-13px;
  width: 258px;
  height: 60px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
}
.homepage-text07 {
  top: 0px;
  left: 0px;
  color: var(--dl-color-ubx_text__dark-100d1bbf9);
  width: 258px;
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: left;
  line-height: 32px;
  text-decoration: none;
}
.homepage-svg {
  top: 9px;
  right: -6px;
  width: 25;
  height: 15;
  position: absolute;
}
.homepage-group62527185 {
  top: 710.3983154296875px;
  left: 0px;
  width: 100%;
  height: 504.716552734375px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
}
.homepage-image10 {
  top: 0px;
  left: 0px;
  width: 305px;
  height: 401px;
  position: absolute;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.homepage-frame227187 {
  top: 0px;
  left: 216.27783203125px;
  width: 89px;
  height: 35.897789001464844px;
  display: flex;
  padding: 9px 16px;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  border-color: transparent;
  background-color: var(--dl-color-ubx_background-gray800e8080);
}
.homepage-text08 {
  color: var(--dl-color-ubx_background-gray0c0b20);
  height: auto;
  align-self: auto;
  text-align: center;
  line-height: 20px;
  text-decoration: none;
}
.homepage-group62027189 {
  top: 415.6016845703125px;
  left: 0px;
  width: 305px;
  height: 89.11486053466797px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
}
.homepage-text09 {
  top: 0px;
  left: 0px;
  color: var(--dl-color-ubx_text__dark-100d1bbf9);
  width: 305px;
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: left;
  line-height: 24px;
  text-decoration: none;
}
.homepage-text10 {
  top: 29px;
  left: 0px;
  color: rgba(43, 45, 50, 1);
  width: 198px;
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: left;
  line-height: 32px;
  text-decoration: none;
}
.homepage-component127192 {
  top: 74px;
  left: 2px;
  width: 68px;
  height: 15.114858627319336px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  border-color: transparent;
}
.homepage-iconoutlinedapplicationstar-i271929217 {
  top: 0px;
  left: 54.2814826965332px;
  width: 14px;
  height: 15.114858627319336px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  border-color: transparent;
}
.homepage-image11 {
  top: 0px;
  left: 0px;
  width: 14px;
  height: 15px;
  position: absolute;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.homepage-image12 {
  top: 0px;
  left: 40.71111297607422px;
  width: 14px;
  height: 15px;
  position: absolute;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.homepage-image13 {
  top: 0px;
  left: 27.1407413482666px;
  width: 14px;
  height: 15px;
  position: absolute;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.homepage-image14 {
  top: 0px;
  left: 13.5703706741333px;
  width: 14px;
  height: 15px;
  position: absolute;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.homepage-iconfilledapplicationstar-i271929218 {
  top: 0px;
  left: 0px;
  width: 14px;
  height: 15.114858627319336px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  border-color: transparent;
}
.homepage-image15 {
  top: 0px;
  left: 0px;
  width: 14px;
  height: 15px;
  position: absolute;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.homepage-group62627193 {
  top: 710.3983154296875px;
  left: 305px;
  width: 306px;
  height: 502.716552734375px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
}
.homepage-image16 {
  top: 0px;
  left: 0.333251953125px;
  width: 305px;
  height: 401px;
  position: absolute;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.homepage-group62427195 {
  top: 415.6016845703125px;
  left: 0px;
  width: 298px;
  height: 87.11486053466797px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
}
.homepage-text11 {
  top: 0px;
  left: 0px;
  color: var(--dl-color-ubx_text__dark-100d1bbf9);
  width: 298px;
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: left;
  line-height: 24px;
  text-decoration: none;
}
.homepage-text12 {
  top: 29px;
  left: 0px;
  color: rgba(43, 45, 50, 1);
  width: 220px;
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: left;
  line-height: 32px;
  text-decoration: none;
}
.homepage-component127198 {
  top: 72px;
  left: 0px;
  width: 68px;
  height: 15.114858627319336px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  border-color: transparent;
}
.homepage-iconoutlinedapplicationstar-i271989217 {
  top: 0px;
  left: 54.2814826965332px;
  width: 14px;
  height: 15.114858627319336px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  border-color: transparent;
}
.homepage-image17 {
  top: 0px;
  left: 0px;
  width: 14px;
  height: 15px;
  position: absolute;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.homepage-image18 {
  top: 0px;
  left: 40.71111297607422px;
  width: 14px;
  height: 15px;
  position: absolute;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.homepage-image19 {
  top: 0px;
  left: 27.1407413482666px;
  width: 14px;
  height: 15px;
  position: absolute;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.homepage-image20 {
  top: 0px;
  left: 13.5703706741333px;
  width: 14px;
  height: 15px;
  position: absolute;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.homepage-iconfilledapplicationstar-i271989218 {
  top: 0px;
  left: 0px;
  width: 14px;
  height: 15.114858627319336px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  border-color: transparent;
}
.homepage-image21 {
  top: 0px;
  left: 0px;
  width: 14px;
  height: 15px;
  position: absolute;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.homepage-group62727199 {
  top: 710.3983154296875px;
  left: 608px;
  width: 308px;
  height: 500.716552734375px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
}
.homepage-image22 {
  top: 0px;
  left: 2.66650390625px;
  width: 305px;
  height: 401px;
  position: absolute;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.homepage-frame327201 {
  top: 0px;
  left: 250.32568359375px;
  width: 58px;
  height: 35.897789001464844px;
  display: flex;
  padding: 9px 16px;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  border-color: transparent;
  background-color: var(--dl-color-ubx_background-gray800e8080);
}
.homepage-text13 {
  color: var(--dl-color-ubx_background-gray0c0b20);
  height: auto;
  align-self: auto;
  text-align: center;
  line-height: 20px;
  text-decoration: none;
}
.homepage-group62027203 {
  top: 415.6016845703125px;
  left: 0px;
  width: 302px;
  height: 85.11486053466797px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
}
.homepage-text14 {
  top: 0px;
  left: 3px;
  color: var(--dl-color-ubx_text__dark-100d1bbf9);
  width: 299px;
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: left;
  line-height: 24px;
  text-decoration: none;
}
.homepage-text15 {
  top: 30px;
  left: 6px;
  color: rgba(43, 45, 50, 1);
  width: 220px;
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: left;
  line-height: 32px;
  text-decoration: none;
}
.homepage-component127206 {
  top: 70px;
  left: 3px;
  width: 68px;
  height: 15.114858627319336px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  border-color: transparent;
}
.homepage-iconoutlinedapplicationstar-i272069217 {
  top: 0px;
  left: 54.2814826965332px;
  width: 14px;
  height: 15.114858627319336px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  border-color: transparent;
}
.homepage-image23 {
  top: 0px;
  left: 0px;
  width: 14px;
  height: 15px;
  position: absolute;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.homepage-image24 {
  top: 0px;
  left: 40.71111297607422px;
  width: 14px;
  height: 15px;
  position: absolute;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.homepage-image25 {
  top: 0px;
  left: 27.1407413482666px;
  width: 14px;
  height: 15px;
  position: absolute;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.homepage-image26 {
  top: 0px;
  left: 13.5703706741333px;
  width: 14px;
  height: 15px;
  position: absolute;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.homepage-iconfilledapplicationstar-i272069218 {
  top: 0px;
  left: 0px;
  width: 14px;
  height: 15.114858627319336px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  border-color: transparent;
}
.homepage-image27 {
  top: 0px;
  left: 0px;
  width: 14px;
  height: 15px;
  position: absolute;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.homepage-button27207 {
  border-radius: 4px;
  border: none;
  top: 444px;
  left: 102px;
  width: 345px;
  height: 45px;
  display: flex;
  position: absolute;
  align-items: flex-start;
}
.homepage-buttongrid-i2720710262260 {
  top: 0px;
  left: 0px;
  width: 345px;
  height: 48px;
  display: flex;
  padding: 12px 105px;
  overflow: hidden;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-radius: 4px;
  border-color: transparent;
  background-color: rgba(234, 178, 82, 1);
}
.homepage-text16 {
  top: 9px;
  left: 130px;
  color: var(--dl-color-ubx_text_light-100d5e157);
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: center;
  line-height: 28px;
  text-decoration: none;
}
.homepage-group627208 {
  top: 108px;
  left: 102px;
  width: 360px;
  height: 242px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
}
.homepage-text17 {
  top: 0px;
  left: 0px;
  color: var(--dl-color-ubx_text__dark-100d1bbf9);
  width: 345px;
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: left;
  line-height: 60px;
  text-decoration: none;
}
.homepage-text18 {
  top: 128px;
  left: 0px;
  color: var(--dl-color-ubx_text__dark-100d1bbf9);
  width: 360px;
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: left;
  line-height: 24px;
  text-decoration: none;
}
.homepage-image28 {
  top: 1389px;
  left: 2px;
  width: 45%;
  height: 534px;
  position: absolute;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.homepage-text19 {
  top: 1489px;
  left: 50%;
  color: var(--dl-color-ubx_text__dark-100d1bbf9);
  width: 305px;
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: left;
  line-height: 48px;
  text-decoration: none;
}
.homepage-text20 {
  top: 1549px;
  left: 50%;
  color: var(--dl-color-ubx_text__dark-100d1bbf9);
  width: 305px;
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: left;
  line-height: 28px;
  text-decoration: none;
}
.homepage-footer27214 {
  top: 2405px;
  left: 2px;
  width: 914px;
  height: 76px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  border-color: transparent;
}
.homepage-image29 {
  top: 0px;
  left: 0px;
  width: 914px;
  height: 76px;
  position: absolute;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
  background-color: rgba(255, 255, 255, 1);
}
.homepage-text21 {
  top: 22px;
  left: 635px;
  color: var(--dl-color-ubx_text__dark-100d1bbf9);
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: right;
  line-height: 20px;
  text-decoration: none;
}
.homepage-group361i27214413474 {
  top: 30px;
  left: 80px;
  width: 147px;
  height: 17px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
}
.homepage-text22 {
  top: 1px;
  left: 0px;
  color: var(--dl-color-default-charcoal23ed6b);
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: left;
  line-height: 16px;
  text-decoration: none;
}
.homepage-image30 {
  top: 0px;
  left: 75px;
  width: 72px;
  height: 16px;
  position: absolute;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.homepage-frame2236i2721410228387 {
  top: 31px;
  left: 280px;
  width: 235px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
}
.homepage-text23 {
  color: var(--dl-color-sme-salmondefault48b21);
  height: auto;
  margin: 0 32px 0 0;
  align-self: auto;
  text-align: left;
  line-height: 16px;
  text-decoration: none;
}
.homepage-text24 {
  color: var(--dl-color-sme-salmondefault48b21);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 16px;
  text-decoration: none;
}
