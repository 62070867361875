.ant-upload.ant-upload-drag {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  background: #fafafa;
  border: 2px dashed #f97663;
  border-radius: 4px;
  cursor: pointer;
  -webkit-transition: border-color 0.3s;
  transition: border-color 0.3s;
}

.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: #f97663;
}
.ant-radio-checked .ant-radio-inner {
  border-color: #f97663 !important ;
}

.ant-radio-checked .ant-radio-inner:after {
  background-color: #f97663;
}

.ant-radio:hover .ant-radio-inner {
  border-color: #f97663;
}
.ant-radio-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.54);
  border-style: solid;
  border-width: 1px;
  border-radius: 100px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-radio-inner::after {
  position: absolute;
  top: 3px;
  left: 3px;
  display: table;
  width: 12px;
  height: 12px;
  background-color: #1890ff;
  border-top: 0;
  border-left: 0;
  border-radius: 8px;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  -webkit-transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  content: " ";
}

.fileContainer .errorsContainer {
  max-width: 300px;
  font-size: 12px;
  color: red;
  position: relative;
  text-align: left;
}
