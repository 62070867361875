.frame2241591187-container {
  min-height: 100vh;
}
.frame2241591187-frame2241591187 {
  width: 75%;
  left: 25%;
  position: absolute;
  height: 2100px;
  display: flex;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  align-items: flex-start;
  border-color: transparent;
}
.frame2241591187-image {
  top: 1391px;
  left: 45%;
  width: 55%;
  height: 532px;
  position: absolute;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
  background-color: var(--dl-color-ubx_background-gray1066c1e);
}
.frame2241591187-group4591189 {
  top: 1741.9874267578125px;
  left: 50%;
  width: 81px;
  height: 15.114858627319336px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
}
.frame2241591187-frame618591190 {
  top: 0px;
  left: 0px;
  width: 81px;
  height: 15.114858627319336px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  border-color: transparent;
}
.frame2241591187-image01 {
  width: 16px;
  height: 16px;
  margin: 0 24px 0 0;
  position: relative;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.frame2241591187-image02 {
  width: 16px;
  height: 16px;
  margin: 0 24px 0 0;
  position: relative;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.frame2241591187-image03 {
  width: 16px;
  height: 16px;
  position: relative;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.frame2241591187-image04 {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 605px;
  position: absolute;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.frame2241591187-text {
  top: 643px;
  left: 0px;
  color: var(--dl-color-ubx_text__dark-100d1bbf9);
  width: 318px;
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: left;
  line-height: 32px;
  text-decoration: none;
}
.frame2241591187-group619591196 {
  top: 1270px;
  left: 4.547473508864641e-13px;
  width: 258px;
  height: 60px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
}
.frame2241591187-text01 {
  top: 0px;
  left: 0px;
  color: var(--dl-color-ubx_text__dark-100d1bbf9);
  width: 258px;
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: left;
  line-height: 32px;
  text-decoration: none;
}
.frame2241591187-svg {
  top: 10px;
  left: 233px;
  width: 25;
  height: 15;
  position: absolute;
}
.frame2241591187-group625591199 {
  top: 710.3983154296875px;
  left: 0px;
  width: 100%;
  height: 504.716552734375px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
}
.frame2241591187-image05 {
  top: 0px;
  left: 0px;
  width: 305px;
  height: 401px;
  position: absolute;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.frame2241591187-frame2591201 {
  top: 0px;
  left: 216.27783203125px;
  width: 89px;
  height: 35.897789001464844px;
  display: flex;
  padding: 9px 16px;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  border-color: transparent;
  background-color: var(--dl-color-ubx_background-gray800e8080);
}
.frame2241591187-text02 {
  color: var(--dl-color-ubx_background-gray0c0b20);
  height: auto;
  align-self: auto;
  text-align: center;
  line-height: 20px;
  text-decoration: none;
}
.frame2241591187-group620591203 {
  top: 415.6016845703125px;
  left: 0px;
  width: 305px;
  height: 89.11486053466797px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
}
.frame2241591187-text03 {
  top: 0px;
  left: 0px;
  color: var(--dl-color-ubx_text__dark-100d1bbf9);
  width: 305px;
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: left;
  line-height: 24px;
  text-decoration: none;
}
.frame2241591187-text04 {
  top: 17px;
  left: 0px;
  color: rgba(43, 45, 50, 1);
  width: 198px;
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: left;
  line-height: 32px;
  text-decoration: none;
}
.frame2241591187-component1591206 {
  top: 74px;
  left: 2px;
  width: 68px;
  height: 15.114858627319336px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  border-color: transparent;
}
.frame2241591187-iconoutlinedapplicationstar-i5912069217 {
  top: 0px;
  left: 54.2814826965332px;
  width: 14px;
  height: 15.114858627319336px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  border-color: transparent;
}
.frame2241591187-image06 {
  top: 0px;
  left: 0px;
  width: 14px;
  height: 15px;
  position: absolute;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.frame2241591187-image07 {
  top: 0px;
  left: 40.71111297607422px;
  width: 14px;
  height: 15px;
  position: absolute;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.frame2241591187-image08 {
  top: 0px;
  left: 27.1407413482666px;
  width: 14px;
  height: 15px;
  position: absolute;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.frame2241591187-image09 {
  top: 0px;
  left: 13.5703706741333px;
  width: 14px;
  height: 15px;
  position: absolute;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.frame2241591187-iconfilledapplicationstar-i5912069218 {
  top: 0px;
  left: 0px;
  width: 14px;
  height: 15.114858627319336px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  border-color: transparent;
}
.frame2241591187-image10 {
  top: 0px;
  left: 0px;
  width: 14px;
  height: 15px;
  position: absolute;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.frame2241591187-group626591207 {
  top: 710.3983154296875px;
  left: 305px;
  width: 306px;
  height: 502.716552734375px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
}
.frame2241591187-image11 {
  top: 0px;
  left: 0.333251953125px;
  width: 305px;
  height: 401px;
  position: absolute;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.frame2241591187-group624591209 {
  top: 415.6016845703125px;
  left: 0px;
  width: 298px;
  height: 87.11486053466797px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
}
.frame2241591187-text05 {
  top: 0px;
  left: 0px;
  color: var(--dl-color-ubx_text__dark-100d1bbf9);
  width: 298px;
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: left;
  line-height: 24px;
  text-decoration: none;
}
.frame2241591187-text06 {
  top: 17px;
  left: 0px;
  color: rgba(43, 45, 50, 1);
  width: 220px;
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: left;
  line-height: 32px;
  text-decoration: none;
}
.frame2241591187-component1591212 {
  top: 72px;
  left: 0px;
  width: 68px;
  height: 15.114858627319336px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  border-color: transparent;
}
.frame2241591187-iconoutlinedapplicationstar-i5912129217 {
  top: 0px;
  left: 54.2814826965332px;
  width: 14px;
  height: 15.114858627319336px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  border-color: transparent;
}
.frame2241591187-image12 {
  top: 0px;
  left: 0px;
  width: 14px;
  height: 15px;
  position: absolute;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.frame2241591187-image13 {
  top: 0px;
  left: 40.71111297607422px;
  width: 14px;
  height: 15px;
  position: absolute;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.frame2241591187-image14 {
  top: 0px;
  left: 27.1407413482666px;
  width: 14px;
  height: 15px;
  position: absolute;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.frame2241591187-image15 {
  top: 0px;
  left: 13.5703706741333px;
  width: 14px;
  height: 15px;
  position: absolute;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.frame2241591187-iconfilledapplicationstar-i5912129218 {
  top: 0px;
  left: 0px;
  width: 14px;
  height: 15.114858627319336px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  border-color: transparent;
}
.frame2241591187-image16 {
  top: 0px;
  left: 0px;
  width: 14px;
  height: 15px;
  position: absolute;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.frame2241591187-group627591213 {
  top: 710.3983154296875px;
  left: 608px;
  width: 308px;
  height: 500.716552734375px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
}
.frame2241591187-image17 {
  top: 0px;
  left: 2.66650390625px;
  width: 305px;
  height: 401px;
  position: absolute;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.frame2241591187-frame3591215 {
  top: 0px;
  left: 250.32568359375px;
  width: 58px;
  height: 35.897789001464844px;
  display: flex;
  padding: 9px 16px;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  border-color: transparent;
  background-color: var(--dl-color-ubx_background-gray800e8080);
}
.frame2241591187-text07 {
  color: var(--dl-color-ubx_background-gray0c0b20);
  height: auto;
  align-self: auto;
  text-align: center;
  line-height: 20px;
  text-decoration: none;
}
.frame2241591187-group620591217 {
  top: 415.6016845703125px;
  left: 0px;
  width: 302px;
  height: 85.11486053466797px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
}
.frame2241591187-text08 {
  top: 0px;
  left: 3px;
  color: var(--dl-color-ubx_text__dark-100d1bbf9);
  width: 299px;
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: left;
  line-height: 24px;
  text-decoration: none;
}
.frame2241591187-text09 {
  top: 16px;
  left: 0px;
  color: rgba(43, 45, 50, 1);
  width: 220px;
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: left;
  line-height: 32px;
  text-decoration: none;
}
.frame2241591187-component1591220 {
  top: 70px;
  left: 3px;
  width: 68px;
  height: 15.114858627319336px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  border-color: transparent;
}
.frame2241591187-iconoutlinedapplicationstar-i5912209217 {
  top: 0px;
  left: 54.2814826965332px;
  width: 14px;
  height: 15.114858627319336px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  border-color: transparent;
}
.frame2241591187-image18 {
  top: 0px;
  left: 0px;
  width: 14px;
  height: 15px;
  position: absolute;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.frame2241591187-image19 {
  top: 0px;
  left: 40.71111297607422px;
  width: 14px;
  height: 15px;
  position: absolute;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.frame2241591187-image20 {
  top: 0px;
  left: 27.1407413482666px;
  width: 14px;
  height: 15px;
  position: absolute;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.frame2241591187-image21 {
  top: 0px;
  left: 13.5703706741333px;
  width: 14px;
  height: 15px;
  position: absolute;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.frame2241591187-iconfilledapplicationstar-i5912209218 {
  top: 0px;
  left: 0px;
  width: 14px;
  height: 15.114858627319336px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  border-color: transparent;
}
.frame2241591187-image22 {
  top: 0px;
  left: 0px;
  width: 14px;
  height: 15px;
  position: absolute;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.frame2241591187-button591221 {
  top: 444px;
  left: 102px;
  width: 345px;
  height: 45px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  border: none;
  border-radius: 4px;
}
.frame2241591187-buttongrid-i59122110262260 {
  top: 0px;
  left: 0px;
  width: 345px;
  height: 45px;
  display: flex;
  padding: 12px 105px;
  overflow: hidden;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-radius: 4px;
  background-color: rgba(234, 178, 82, 1);
}
.frame2241591187-text10 {
  top: 9px;
  left: 130px;
  color: var(--dl-color-ubx_text_light-100d5e157);
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: center;
  line-height: 28px;
  text-decoration: none;
}
.frame2241591187-group6591222 {
  top: 108px;
  left: 102px;
  width: 360px;
  height: 242px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
}
.frame2241591187-text11 {
  top: 0px;
  left: 0px;
  color: var(--dl-color-ubx_text__dark-100d1bbf9);
  width: 345px;
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: left;
  line-height: 60px;
  text-decoration: none;
}
.frame2241591187-text12 {
  top: 128px;
  left: 0px;
  color: var(--dl-color-ubx_text__dark-100d1bbf9);
  width: 360px;
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: left;
  line-height: 24px;
  text-decoration: none;
}
.frame2241591187-text13 {
  top: 236px;
  left: 102px;
  color: var(--dl-color-ubx_text__dark-100d1bbf9);
  width: 360px;
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: left;
  line-height: 24px;
  text-decoration: none;
}
.frame2241591187-image23 {
  top: 1389px;
  left: 2px;
  width: 45%;
  height: 534px;
  position: absolute;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.frame2241591187-text14 {
  top: 1489px;
  left: 50%;
  color: var(--dl-color-ubx_text__dark-100d1bbf9);
  width: 305px;
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: left;
  line-height: 48px;
  text-decoration: none;
}
.frame2241591187-text15 {
  top: 1549px;
  left: 50%;
  right: 46px;
  color: var(--dl-color-ubx_text__dark-100d1bbf9);
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: left;
  line-height: 28px;
  text-decoration: none;
}
.frame2241591187-footer591229 {
  top: 2405px;
  left: 2px;
  width: 914px;
  height: 76px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  border-color: transparent;
}
.frame2241591187-image24 {
  top: 0px;
  left: 0px;
  width: 914px;
  height: 76px;
  position: absolute;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
  background-color: rgba(255, 255, 255, 1);
}
.frame2241591187-text16 {
  top: 22px;
  left: 635px;
  color: var(--dl-color-ubx_text__dark-100d1bbf9);
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: right;
  line-height: 20px;
  text-decoration: none;
}
.frame2241591187-group361i591229413474 {
  top: 30px;
  left: 80px;
  width: 147px;
  height: 17px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
}
.frame2241591187-text17 {
  top: 1px;
  left: 0px;
  color: var(--dl-color-default-charcoal23ed6b);
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: left;
  line-height: 16px;
  text-decoration: none;
}
.frame2241591187-image25 {
  top: 0px;
  left: 75px;
  width: 72px;
  height: 16px;
  position: absolute;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.frame2241591187-frame2236i59122910228387 {
  top: 31px;
  left: 280px;
  width: 235px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
}
.frame2241591187-text18 {
  color: var(--dl-color-sme-salmondefault48b21);
  height: auto;
  margin: 0 32px 0 0;
  align-self: auto;
  text-align: left;
  line-height: 16px;
  text-decoration: none;
}
.frame2241591187-text19 {
  color: var(--dl-color-sme-salmondefault48b21);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 16px;
  text-decoration: none;
}
