:root {
  --dl-color-gray-500: #595959;
  --dl-color-gray-700: #999999;
  --dl-color-gray-900: #d9d9d9;
  --dl-size-size-large: 144px;
  --dl-size-size-small: 48px;
  --dl-color-danger-300: #a22020;
  --dl-color-danger-500: #bf2626;
  --dl-color-danger-700: #e14747;
  --dl-color-gray-black: #000000;
  --dl-color-gray-white: #ffffff;
  --dl-size-size-medium: 96px;
  --dl-size-size-xlarge: 192px;
  --dl-size-size-xsmall: 16px;
  --dl-space-space-unit: 16px;
  --dl-color-primary-100: #003eb3;
  --dl-color-primary-300: #0074f0;
  --dl-color-primary-500: #14a9ff;
  --dl-color-primary-700: #85dcff;
  --dl-color-success-300: #199033;
  --dl-color-success-500: #32a94c;
  --dl-color-success-700: #4cc366;
  --dl-size-size-xxlarge: 288px;
  --dl-size-size-maxwidth: 1400px;
  --dl-radius-radius-round: 50%;
  --dl-space-space-halfunit: 8px;
  --dl-space-space-sixunits: 96px;
  --dl-space-space-twounits: 32px;
  --dl-radius-radius-radius2: 2px;
  --dl-radius-radius-radius4: 4px;
  --dl-radius-radius-radius8: 8px;
  --dl-space-space-fiveunits: 80px;
  --dl-space-space-fourunits: 64px;
  --dl-space-space-threeunits: 48px;
  --dl-space-space-oneandhalfunits: 24px;
  --dl-color-default-charcoal23ed6b: rgba(83, 86, 94, 1);
  --dl-color-sme-salmondefault48b21: rgba(255, 106, 84, 1);
  --dl-color-ubx_text__dark-100d1bbf9: rgba(43, 45, 50, 1);
  --dl-color-ubx_text_light-100d5e157: rgba(255, 255, 255, 1);
  --dl-color-ubx_background-gray0c0b20: rgba(255, 255, 255, 1);
  --dl-color-ubx_background-gray1066c1e: rgba(250, 250, 250, 1);
  --dl-color-ubx_background-gray800e8080: rgba(62, 65, 73, 1);
}
.button {
  color: var(--dl-color-gray-black);
  display: inline-block;
  padding: 0.5rem 1rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.input {
  color: var(--dl-color-gray-black);
  cursor: auto;
  padding: 0.5rem 1rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.textarea {
  color: var(--dl-color-gray-black);
  cursor: auto;
  padding: 0.5rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.list {
  width: 100%;
  margin: 1em 0px 1em 0px;
  display: block;
  padding: 0px 0px 0px 1.5rem;
  list-style-type: none;
  list-style-position: outside;
}
.list-item {
  display: list-item;
}
.teleport-show {
  display: flex !important;
}
.lightH3 {
  font-size: 28px;
  font-style: normal;
  font-family: Inter;
  font-weight: 300px;
  font-stretch: normal;
}
.boldSmall {
  font-size: 14px;
  font-style: normal;
  font-family: Inter;
  font-weight: 700px;
  font-stretch: normal;
}
.lightBody {
  font-size: 16px;
  font-style: normal;
  font-family: Inter;
  font-weight: 300px;
  font-stretch: normal;
}
.description {
  font-size: 18px;
  font-style: normal;
  font-family: Inter;
  font-weight: 300px;
  font-stretch: normal;
}
.lightH1 {
  font-size: 48px;
  font-style: normal;
  font-family: Inter;
  font-weight: 300px;
  font-stretch: normal;
}
.lightH2 {
  font-size: 36px;
  font-style: normal;
  font-family: Inter;
  font-weight: 300px;
  font-stretch: normal;
}
.lightH4 {
  font-size: 18px;
  font-style: normal;
  font-family: Inter;
  font-weight: 300px;
  font-stretch: normal;
}
.regularSmall {
  font-size: 14px;
  font-style: normal;
  font-family: Inter;
  font-weight: 400px;
  font-stretch: normal;
}
.regularXsmall {
  font-size: 12px;
  font-style: normal;
  font-family: Inter;
  font-weight: 400px;
  font-stretch: normal;
}
.content {
  font-size: 16px;
  font-family: Inter;
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
