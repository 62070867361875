.ant-carousel .slick-dots-bottom {
  bottom: -35px !important;
}

.ant-carousel .slick-dots li.slick-active button {
  background: #858997 !important;
  opacity: 1 !important;
}

.ant-carousel .slick-dots li button {
  background: #b7b9c0 !important;
}

.ant-carousel .slick-dots li.slick-active {
  width: 25px !important;
  height: 10px !important;
}

.ant-carousel .slick-dots li {
  width: 10px !important;
  height: 10px !important;
}
