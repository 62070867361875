:root {
    --rmdp-primary-sentro-color: #f97663;
  }
  
  .sentroColor .rmdp-wrapper {
    border: 1px solid var(--rmdp-primary-sentro-color);
    box-shadow: 0 0 5px var(--rmdp-primary-sentro-color);
  }
  
  .sentroColor .rmdp-panel-body li {
    background-color: var(--rmdp-primary-sentro-color);
    box-shadow: 0 0 2px var(--rmdp-primary-sentro-color);
  }
  
  .sentroColor .rmdp-week-day {
    color: #2b2d32;
    font-weight: 500;
  }
  
  .sentroColor .rmdp-day.rmdp-deactive {
    color: var(--rmdp-primary-sentro-color);
  }
  
  .sentroColor .rmdp-range {
    background-color: var(--rmdp-primary-sentro-color);
    box-shadow: 0 0 3px var(--rmdp-primary-sentro-color);
  }
  
  .sentroColor .rmdp-arrow {
    border: solid var(--rmdp-primary-sentro-color);
    border-width: 0 2px 2px 0;
  }
  
  .sentroColor .rmdp-arrow-container:hover {
    background-color: var(--rmdp-primary-sentro-color);
    box-shadow: 0 0 3px var(--rmdp-primary-sentro-color);
  }
  
  .sentroColor .rmdp-panel-body::-webkit-scrollbar-thumb {
    background: var(--rmdp-primary-sentroColor);
  }
  
  .sentroColor .rmdp-day.rmdp-today span {
    background-color: var(--rmdp-today-sentroColor);
  }
  
  .sentroColor .rmdp-rtl .rmdp-panel {
    border-left: unset;
    border-right: 1px solid var(--rmdp-primary-sentro-color);
  }
  
  .sentroColor .rmdp-day.rmdp-selected span:not(.highlight) {
    background-color: var(--rmdp-primary-sentro-color);
    box-shadow: 0 0 3px var(--rmdp-primary-sentro-color);
  }
  
  .sentroColor .rmdp-day:not(.rmdp-day-hidden) span:hover {
    background-color: var(--rmdp-primary-sentro-color) !important;
  }
  
  .sentroColor .b-deselect {
    color: var(--rmdp-primary-sentro-color);
    background-color: white;
  }
  
  .sentroColor .rmdp-action-button {
    color: var(--rmdp-primary-sentro-color);
  }
  
  .sentroColor .rmdp-button:not(.rmdp-action-button) {
    background-color: var(--rmdp-primary-sentro-color);
  }
  
  .sentroColor .rmdp-button:not(.rmdp-action-button):hover {
    background-color: var(--rmdp-primary-sentro-color);
  }

  .sentroColor .rmdp-toolbar {
    padding: 10px 18px;
    justify-content: end;
  }
  
  .sentroColor .rmdp-toolbar div {
    background-color: var(--rmdp-primary-sentro-color);
  }

  .sentroColor .rmdp-header-values {
    color: #FF6B55;
    font-weight: 600;
  }

  @media only screen and (max-width: 600px) {
    .sentroColor .rmdp-toolbar {
      padding: 10px 18px;
      display: inherit;
    }
  }