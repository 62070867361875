@import url('https://cdn.jsdelivr.net/npm/inter-ui@3.11.0/inter.min.css');
@import '~react-image-gallery/styles/css/image-gallery.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Inter';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button:focus {
  outline: none !important;
}
