.m-leftist-home-page38234-container {
  min-height: 100vh;
}
.m-leftist-home-page38234-m-leftist-home-page38234 {
  width: 100%;
  height: 3343px;
  display: flex;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  align-items: flex-start;
  border-color: transparent;
  background-color: var(--dl-color-ubx_background-gray0c0b20);
}
.m-leftist-home-page38234-mobilenavx38235 {
  top: 0px;
  left: 0px;
  width: 360px;
  height: 72px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  border-color: transparent;
}
.m-leftist-home-page38234-mobilenav-i382355121218 {
  top: 0px;
  left: 0px;
  width: 360px;
  height: 72px;
  display: flex;
  overflow: hidden;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  border-color: transparent;
}
.m-leftist-home-page38234-group268i3823551212185121158 {
  top: 22px;
  left: 72px;
  width: 71px;
  height: 28px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
}
.m-leftist-home-page38234-frame27i3823551212185121207 {
  top: 0px;
  left: 0px;
  width: 71px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
}
.m-leftist-home-page38234-text {
  color: var(--dl-color-ubx_text__dark-100d1bbf9);
  height: auto;
  align-self: auto;
  text-align: left;
  line-height: 28px;
  text-decoration: none;
}
.m-leftist-home-page38234-image {
  top: 24px;
  left: 24px;
  width: 24px;
  height: 24px;
  position: absolute;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.m-leftist-home-page38234-frame25i3823551212185121208 {
  top: 24px;
  left: 264px;
  width: 72px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
}
.m-leftist-home-page38234-image01 {
  width: 24px;
  height: 24px;
  margin: 0 24px 0 0;
  position: relative;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.m-leftist-home-page38234-image02 {
  width: 24px;
  height: 24px;
  position: relative;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.m-leftist-home-page38234-frame14i3823551212185121211 {
  top: 18px;
  left: 322px;
  width: 16px;
  height: 16px;
  display: flex;
  overflow: hidden;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  border-color: transparent;
  border-radius: 16px;
  background-color: var(--dl-color-ubx_system-red800dd8427);
}
.m-leftist-home-page38234-text01 {
  top: 4px;
  left: 4px;
  color: var(--dl-color-ubx_text_light-100d5e157);
  width: 8px;
  height: auto;
  position: absolute;
  font-size: 9px;
  align-self: auto;
  font-style: normal;
  text-align: center;
  font-family: Inter;
  font-weight: 700px;
  line-height: 18px;
  font-stretch: normal;
  text-decoration: none;
}
.m-leftist-home-page38234-group36338236 {
  top: 3287px;
  left: 0px;
  width: 360px;
  height: 56px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  background-color: var(--dl-color-ubx_background-gray0c0b20);
}
.m-leftist-home-page38234-group21038238 {
  top: 22px;
  left: 121px;
  width: 119px;
  height: 12px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  border-color: transparent;
}
.m-leftist-home-page38234-text02 {
  top: 0px;
  left: 0px;
  color: var(--dl-color-default-charcoal23ed6b);
  height: auto;
  position: absolute;
  font-size: 10px;
  align-self: auto;
  font-style: normal;
  text-align: right;
  font-family: Inter;
  font-weight: 400px;
  line-height: normal;
  font-stretch: normal;
  text-decoration: none;
}
.m-leftist-home-page38234-image03 {
  top: 0px;
  left: 65px;
  width: 54px;
  height: 12px;
  position: absolute;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.m-leftist-home-page38234-image04 {
  width: 100%;
  height: 512px;
  position: absolute;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.m-leftist-home-page38234-group36038240 {
  top: 120px;
  left: 16px;
  width: 242px;
  height: 256px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
}
.m-leftist-home-page38234-text03 {
  top: 0px;
  left: 0px;
  color: var(--dl-color-ubx_text__dark-100d1bbf9);
  width: 242px;
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: left;
  line-height: 48px;
  text-decoration: none;
}
.m-leftist-home-page38234-text04 {
  top: 112px;
  left: 0px;
  color: var(--dl-color-ubx_text__dark-100d1bbf9);
  width: 242px;
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: left;
  line-height: 24px;
  text-decoration: none;
}
.m-leftist-home-page38234-button38243 {
  top: 420px;
  left: 16px;
  width: 328px;
  height: 48px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  border-color: transparent;
  border-radius: 4px;
}
.m-leftist-home-page38234-buttongrid-i3824310262260 {
  top: 0px;
  left: 0px;
  width: 328px;
  height: 48px;
  display: flex;
  padding: 12px 105px;
  overflow: hidden;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  border-radius: 4px;
}
.m-leftist-home-page38234-text05 {
  top: 10px;
  left: 121.59420013427734px;
  color: var(--dl-color-ubx_text_light-100d5e157);
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: center;
  line-height: 28px;
  text-decoration: none;
}
.m-leftist-home-page38234-group62538244 {
  top: 672px;
  left: -0.40576171875px;
  display: flex;
  flex-direction: column;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
}
.m-leftist-home-page38234-image05 {
  top: 0px;
  left: 0px;
  width: 360px;
  height: 424px;
  position: absolute;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.m-leftist-home-page38234-frame238246 {
  top: 0px;
  left: 255px;
  width: 105px;
  display: flex;
  padding: 9px 16px;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  background-color: var(--dl-color-ubx_background-gray800e8080);
}
.m-leftist-home-page38234-text06 {
  color: var(--dl-color-ubx_background-gray0c0b20);
  height: auto;
  align-self: auto;
  text-align: center;
  line-height: 20px;
  text-decoration: none;
}
.m-leftist-home-page38234-group62038248 {
  top: 440px;
  left: 32.40576171875px;
  width: 251px;
  height: 96px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
}
.m-leftist-home-page38234-text07 {
  top: 0px;
  left: 0px;
  color: var(--dl-color-ubx_text__dark-100d1bbf9);
  width: 251px;
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: left;
  line-height: 24px;
  text-decoration: none;
}
.m-leftist-home-page38234-text08 {
  top: 32px;
  left: 0px;
  color: rgba(43, 45, 50, 1);
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: left;
  line-height: 32px;
  text-decoration: none;
}
.m-leftist-home-page38234-component138251 {
  top: 80px;
  left: 0px;
  width: 80px;
  height: 16px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  border-color: transparent;
}
.m-leftist-home-page38234-iconoutlinedapplicationstar-i382519217 {
  top: 0px;
  left: 64px;
  width: 16px;
  height: 16px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  border-color: transparent;
}
.m-leftist-home-page38234-image06 {
  top: 0px;
  left: 0px;
  width: 16px;
  height: 16px;
  position: absolute;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.m-leftist-home-page38234-image07 {
  top: 0px;
  left: 48px;
  width: 16px;
  height: 16px;
  position: absolute;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.m-leftist-home-page38234-image08 {
  top: 0px;
  left: 32px;
  width: 16px;
  height: 16px;
  position: absolute;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.m-leftist-home-page38234-image09 {
  top: 0px;
  left: 16px;
  width: 16px;
  height: 16px;
  position: absolute;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.m-leftist-home-page38234-iconfilledapplicationstar-i382519218 {
  top: 0px;
  left: 0px;
  width: 16px;
  height: 16px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  border-color: transparent;
}
.m-leftist-home-page38234-image10 {
  top: 0px;
  left: 0px;
  width: 16px;
  height: 16px;
  position: absolute;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.m-leftist-home-page38234-text09 {
  top: 616px;
  left: 59.5px;
  color: var(--dl-color-ubx_text__dark-100d1bbf9);
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: center;
  line-height: 32px;
  text-decoration: none;
}
.m-leftist-home-page38234-group62638253 {
  top: 1240px;
  left: 0px;
  width: 360px;
  height: 536px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
}
.m-leftist-home-page38234-image11 {
  top: 0px;
  left: 0px;
  width: 360px;
  height: 424px;
  position: absolute;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.m-leftist-home-page38234-group62438255 {
  top: 440px;
  left: 32px;
  width: 251px;
  height: 96px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
}
.m-leftist-home-page38234-text10 {
  top: 0px;
  left: 0px;
  color: var(--dl-color-ubx_text__dark-100d1bbf9);
  width: 251px;
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: left;
  line-height: 24px;
  text-decoration: none;
}
.m-leftist-home-page38234-text11 {
  top: 32px;
  left: 0px;
  color: rgba(43, 45, 50, 1);
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: left;
  line-height: 32px;
  text-decoration: none;
}
.m-leftist-home-page38234-component138258 {
  top: 80px;
  left: 0px;
  width: 80px;
  height: 16px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  border-color: transparent;
}
.m-leftist-home-page38234-iconoutlinedapplicationstar-i382589217 {
  top: 0px;
  left: 64px;
  width: 16px;
  height: 16px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  border-color: transparent;
}
.m-leftist-home-page38234-image12 {
  top: 0px;
  left: 0px;
  width: 16px;
  height: 16px;
  position: absolute;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.m-leftist-home-page38234-image13 {
  top: 0px;
  left: 48px;
  width: 16px;
  height: 16px;
  position: absolute;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.m-leftist-home-page38234-image14 {
  top: 0px;
  left: 32px;
  width: 16px;
  height: 16px;
  position: absolute;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.m-leftist-home-page38234-image15 {
  top: 0px;
  left: 16px;
  width: 16px;
  height: 16px;
  position: absolute;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.m-leftist-home-page38234-iconfilledapplicationstar-i382589218 {
  top: 0px;
  left: 0px;
  width: 16px;
  height: 16px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  border-color: transparent;
}
.m-leftist-home-page38234-image16 {
  top: 0px;
  left: 0px;
  width: 16px;
  height: 16px;
  position: absolute;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.m-leftist-home-page38234-group62738259 {
  top: 1808px;
  left: -0.40576171875px;
  width: 360px;
  height: 536px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
}
.m-leftist-home-page38234-image17 {
  top: 0px;
  left: 0px;
  width: 360px;
  height: 424px;
  position: absolute;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.m-leftist-home-page38234-frame338261 {
  top: 0px;
  left: 292px;
  width: 68px;
  display: flex;
  padding: 9px 16px;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  background-color: var(--dl-color-ubx_background-gray800e8080);
}
.m-leftist-home-page38234-text12 {
  color: var(--dl-color-ubx_background-gray0c0b20);
  height: auto;
  align-self: auto;
  text-align: center;
  line-height: 20px;
  text-decoration: none;
}
.m-leftist-home-page38234-group62038263 {
  top: 440px;
  left: 33.7890625px;
  width: 251px;
  height: 96px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
}
.m-leftist-home-page38234-text13 {
  top: 0px;
  left: 0px;
  color: var(--dl-color-ubx_text__dark-100d1bbf9);
  width: 251px;
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: left;
  line-height: 24px;
  text-decoration: none;
}
.m-leftist-home-page38234-text14 {
  top: 32px;
  left: 0px;
  color: rgba(43, 45, 50, 1);
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: left;
  line-height: 32px;
  text-decoration: none;
}
.m-leftist-home-page38234-component138266 {
  top: 80px;
  left: 0px;
  width: 80px;
  height: 16px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  border-color: transparent;
}
.m-leftist-home-page38234-iconoutlinedapplicationstar-i382669217 {
  top: 0px;
  left: 64px;
  width: 16px;
  height: 16px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  border-color: transparent;
}
.m-leftist-home-page38234-image18 {
  top: 0px;
  left: 0px;
  width: 16px;
  height: 16px;
  position: absolute;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.m-leftist-home-page38234-image19 {
  top: 0px;
  left: 48px;
  width: 16px;
  height: 16px;
  position: absolute;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.m-leftist-home-page38234-image20 {
  top: 0px;
  left: 32px;
  width: 16px;
  height: 16px;
  position: absolute;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.m-leftist-home-page38234-image21 {
  top: 0px;
  left: 16px;
  width: 16px;
  height: 16px;
  position: absolute;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.m-leftist-home-page38234-iconfilledapplicationstar-i382669218 {
  top: 0px;
  left: 0px;
  width: 16px;
  height: 16px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  border-color: transparent;
}
.m-leftist-home-page38234-image22 {
  top: 0px;
  left: 0px;
  width: 16px;
  height: 16px;
  position: absolute;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.m-leftist-home-page38234-group61938267 {
  top: 2392px;
  left: 65px;
  width: 230px;
  height: 30px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
}
.m-leftist-home-page38234-text15 {
  top: 0px;
  left: 0px;
  color: var(--dl-color-ubx_text__dark-100d1bbf9);
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: left;
  line-height: 30px;
  text-decoration: none;
}
.m-leftist-home-page38234-svg {
  top: 7px;
  left: 200px;
  width: 30;
  height: 16;
  position: absolute;
}
.m-leftist-home-page38234-image23 {
  top: 2486px;
  left: 0px;
  width: 100%;
  height: 564px;
  position: absolute;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
  background-color: var(--dl-color-ubx_background-gray1066c1e);
}
.m-leftist-home-page38234-group63238271 {
  top: 2526px;
  left: 32px;
  width: 296px;
  height: 296px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
}
.m-leftist-home-page38234-group438272 {
  top: 280px;
  left: 0px;
  width: 96px;
  height: 16px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
}
.m-leftist-home-page38234-frame61838273 {
  top: 0px;
  left: 0px;
  width: 96px;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
}
.m-leftist-home-page38234-image24 {
  width: 16px;
  height: 16px;
  margin: 0 24px 0 0;
  position: relative;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.m-leftist-home-page38234-image25 {
  width: 16px;
  height: 16px;
  margin: 0 24px 0 0;
  position: relative;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.m-leftist-home-page38234-image26 {
  width: 16px;
  height: 16px;
  position: relative;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
.m-leftist-home-page38234-text16 {
  top: 0px;
  left: 0px;
  color: var(--dl-color-ubx_text__dark-100d1bbf9);
  width: 296px;
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: left;
  line-height: 48px;
  text-decoration: none;
}
.m-leftist-home-page38234-text17 {
  top: 64px;
  left: 0px;
  color: var(--dl-color-ubx_text__dark-100d1bbf9);
  width: 296px;
  height: auto;
  position: absolute;
  align-self: auto;
  text-align: left;
  line-height: 28px;
  text-decoration: none;
}
.m-leftist-home-page38234-image27 {
  top: 2862px;
  left: 0px;
  width: 100%;
  height: 425px;
  position: absolute;
  box-sizing: border-box;
  object-fit: cover;
  border-color: transparent;
}
